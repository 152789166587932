<template>
  <div class="main">
    <div class="banner">
      <div class="layout">
        <div class="cl">
          <!--免费试用-->
          <h1 class="fl h1">{{$t('FreeTrial')}}</h1>
          <div class="fr">
            <div class="cl">
              <router-link class="fr btn-login" :to="{name:'AdminLogin'}">{{$t('MerchantLogin')}}</router-link>
            </div>
            <!--9月玩熟 UNU Travel 平台-->
            <div class="p1">{{ $t('TryOut1') }}</div>
            <!--上架旅行社產品，裝修好自己的網上旅行社-->
            <div class="p1">{{ $t('TryOut2') }}</div>
            <!--作好最佳準備，待疫情結束，馬上開始收客-->
            <div class="p1">{{ $t('TryOut3') }}</div>
            <!--享受旅遊業爆炸式增長！-->
            <div class="p1">{{ $t('TryOut4') }}</div>
            <!--*試用期間，若能提出有價值的改進建議，有機會免費獲得 1-3個月「白銀會員」獎勵！-->
            <div class="tip">{{ $t('TryOut5') }}</div>
          </div>
        </div>
        <div>
          <router-link class="btn-registered" :to="{name:'AdminRegistered'}">{{$t('TryItNow')}}</router-link>
        </div>
      </div>
    </div>
    <!--註冊說明-->
    <h2 class="h2">{{$t('Joining instructions')}}</h2>
    <div class="layout">
      <div class="card-box cl">
        <!--註冊須知-->
        <div class="item" @click="openDetail('1')">
          <div class="item-inner">
            <img class="ico" src="../../assets/images/joinus/icon1.png"/>
            <div class="title">{{$t('Join Policy')}}</div>
          </div>
        </div>
        <!--會員方案-->
        <!--<div class="item" @click="openDetail('2')">
          <div class="item-inner">
            <img class="ico" src="../../assets/images/joinus/icon2.png"/>
            <div class="title">{{$t('Affiliate Program')}}</div>
          </div>
        </div>-->
        <!--準備材料-->
        <div class="item" @click="openDetail('3')">
          <div class="item-inner">
            <img class="ico" src="../../assets/images/joinus/icon3.png"/>
            <div class="title">{{$t('Prepare materials')}}</div>
          </div>
        </div>
        <!--查詢註冊-->
        <div class="item" @click="openDetail('4')">
          <div class="item-inner">
            <img class="ico" src="../../assets/images/joinus/icon4.png"/>
            <div class="title">{{$t('Check join')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="registration-process">
      <div class="layout">
        <!--註冊流程-->
        <div class="title">{{$t('Registration process')}}</div>
        <div class="step-box cl">
          <!--註冊申請-->
          <div class="item">
            <div class="num">1</div>
            <div class="tit">{{$t('Application for registration')}}</div>
            <!--工作日最快1小時-->
            <div class="ps">{{$t('ps1')}}</div>
            <div class="arr">
              <img class="img" src="../../assets/images/joinus/arrow.png"/>
            </div>
          </div>
          <!--平台審核-->
          <div class="item">
            <div class="num">2</div>
            <div class="tit">{{$t('Platform audit')}}</div>
            <!--約3-7個工作日-->
            <div class="ps">{{$t('ps2')}}</div>
            <div class="arr">
              <img class="img" src="../../assets/images/joinus/arrow.png"/>
            </div>
          </div>
          <!--簽署協議-->
          <div class="item">
            <div class="num">3</div>
            <div class="tit">{{$t('Sign contract')}}</div>
            <!--電子約1個工作日-->
            <div class="ps">{{$t('ps3')}}</div>
            <div class="arr">
              <img class="img" src="../../assets/images/joinus/arrow.png"/>
            </div>
          </div>
          <!--成功註冊-->
          <div class="item">
            <div class="num">4</div>
            <div class="tit">{{$t('Successfully registered')}}</div>
            <!--開通商戶賬號-->
            <div class="ps">{{$t('ps4')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout">
      <template v-if="$i18n.locale==='zh-cn'">
        <h2 class="h2">加入「一游」<span>成为平台的一份子</span></h2>
        <div>
          <div class="adv-card">
            <div class="title">多元灵活的产品管理</div>
            <div class="cont">简洁直接的产品管理后台，模组化的内容呈现，支持图文影视等多媒体，让您的产品更具吸引力。</div>
          </div>
          <div class="adv-card">
            <div class="title">订单及财务管理</div>
            <div class="cont">表单式管理订单及财务资料，清晰有序地呈现各项数据，让您轻松处理产品订单与营收数据。</div>
          </div>
          <div class="adv-card">
            <div class="title">旅团报价系统</div>
            <div class="cont">团体免费提出报价申请，您可以即时了解客户的旅游需要及喜好，方便快捷地提供度身订造的报价回覆，促进成交。</div>
          </div>
          <div class="adv-card">
            <div class="title">专业数码营销服务</div>
            <div class="cont">产品营销有烦恼？我们提供精准有效的数码营销方案，让更多人认识您和您的产品。</div>
          </div>
          <div class="adv-card">
            <div class="title">优秀的用户体验</div>
            <div class="cont">平台致力打造流畅的用户体验，突显您的产品的过人之处，提升销售量。</div>
          </div>
          <div class="adv-card">
            <div class="title">多种线上支付</div>
            <div class="cont">「一游」平台支持多种线上支付方式，让客人轻松快捷地完成付款。</div>
          </div>
        </div>
      </template>
      <template v-if="$i18n.locale==='en'">
        <h2 class="h2">Join UNU Travel<span>Be part of the platform</span></h2>
        <div>
          <div class="adv-card">
            <div class="title">Diversified and flexible product management</div>
            <div class="cont">The concise and direct product management background, modular content presentation and multimedia support can make your products more attractive.</div>
          </div>
          <div class="adv-card">
            <div class="title">Order and financial management</div>
            <div class="cont">Manage orders and financial data in form, present various data in a clear and orderly manner, allowing you to process orders and revenue data easily.</div>
          </div>
          <div class="adv-card">
            <div class="title">Tour Group Quotation System</div>
            <div class="cont">Groups can submit free quotation applications, and understand the travel needs and preferences of customers instantly, and provide tailor-made quotation responses quickly and easily to facilitate transactions.</div>
          </div>
          <div class="adv-card">
            <div class="title">Professional digital marketing services</div>
            <div class="cont">Have trouble with product marketing? We provide accurate and effective digital marketing solutions to let more people know you and your products.</div>
          </div>
          <div class="adv-card">
            <div class="title">Excellent user experience</div>
            <div class="cont">The platform is committed to creating a smooth user experience, stand out the excellence of your products and increasing sales.</div>
          </div>
          <div class="adv-card">
            <div class="title">Various online payments</div>
            <div class="cont">Our platform supports a variety of online payment methods and allow guests to complete payments easily and quickly.</div>
          </div>
        </div>
      </template>
      <template v-else>
        <h2 class="h2">加入「一遊」<span>成為平台的一份子</span></h2>
        <div>
          <div class="adv-card">
            <div class="title">多元靈活的產品管理</div>
            <div class="cont">簡潔直接的產品管理後台，模組化的內容呈現，支持圖文影視等多媒體，讓您的產品更具吸引力。</div>
          </div>
          <div class="adv-card">
            <div class="title">訂單及財務管理</div>
            <div class="cont">表單式管理訂單及財務資料，清晰有序地呈現各項數據，讓您輕鬆處理產品訂單與營收數據。</div>
          </div>
          <div class="adv-card">
            <div class="title">旅團報價系統</div>
            <div class="cont">團體免費提出報價申請，您可以即時了解客戶的旅遊需要及喜好，方便快捷地提供度身訂造的報價回覆，促進成交。</div>
          </div>
          <div class="adv-card">
            <div class="title">專業數碼營銷服務</div>
            <div class="cont">產品營銷有煩惱？我們提供精準有效的數碼營銷方案，讓更多人認識您和您的產品。</div>
          </div>
          <div class="adv-card">
            <div class="title">優秀的用戶體驗</div>
            <div class="cont">平台致力打造流暢的用戶體驗，突顯您的產品的過人之處，提升銷售量。</div>
          </div>
          <div class="adv-card">
            <div class="title">多種線上支付</div>
            <div class="cont">「一遊」平台支持多種線上支付方式，讓客人輕鬆快捷地完成付款。</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'joinus',
    i18n: {
      messages: {
        'zh-cn': {
          ps1: '工作日最快1小时',
          ps2: '约3-7个工作日',
          ps3: '电子约1个工作日',
          ps4: '开通商户账号',
          FreeTrial: '免费试用',
          TryItNow: '立即试用',
          TryOut1: 'UNU Travel 平台',
          TryOut2: '上架旅行社产品，装修好自己的网上旅行社',
          TryOut3: '作好最佳准备，马上开始收客',
          TryOut4: '享受旅游业爆炸式增长！ ',
          TryOut5: '*试用期间，若能提出有价值的改进建议，有机会免费获得 1-3个月「白银会员」奖励！ '
        },
        'zh-hk': {
          ps1: '工作日最快1小時',
          ps2: '約3-7個工作日',
          ps3: '電子約1個工作日',
          ps4: '開通商戶賬號',
          FreeTrial: '免費試用',
          TryItNow: '立即試用',
          TryOut1: 'UNU Travel 平台',
          TryOut2: '上架旅行社產品，裝修好自己的網上旅行社',
          TryOut3: '作好最佳準備，馬上開始收客',
          TryOut4: '享受旅遊業爆炸式增長！',
          TryOut5: '*試用期間，若能提出有價值的改進建議，有機會免費獲得 1-3個月「白銀會員」獎勵！'
        },
        'en': {
          ps1: 'Only need 1 hour on working day',
          ps2: 'Around 3-7 working days',
          ps3: 'Electronic contract need around 1 working day',
          ps4: 'Open a merchant account',
          FreeTrial: 'Free trial',
          TryItNow: 'Try it now',
          TryOut1: 'UNU Travel platform',
          TryOut2: 'Launch travel agency products and decorate your own online shop.',
          TryOut3: 'Make the best preparations and start accepting customers.',
          TryOut4: 'Enjoy the explosive growth of tourism!',
          TryOut5: ' '
        }
      }
    },
    methods: {
      openDetail(current) {
        const routeUrl = this.$router.resolve({name: 'JoinusDetail', query: {current}});
        window.open(routeUrl.href, '_blank');
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    margin-bottom:-30px;padding-bottom:30px;background-color:#f4f4f4;
    .banner{
      height:570px;background:url(../../assets/images/joinus/banner.jpg) no-repeat center center;background-size:cover;
      .p1{padding-bottom:15px;color:#fff;font-size:20px;}
      .tip{color:#fff;}
    }
    .h1{margin:190px 0 100px;color:#fff;font-size:72px;font-weight:bold;}
    .h2{
      margin-top:80px;text-align:center;color:#292c2d;font-size:48px;
      span{display:block;margin-top:16px;font-size:32px;}
    }
    .btn-login{margin:60px 0;padding:11px 20px;color:#fff;font-size:16px;border:1px solid #fff;border-radius:4px;}
    .btn-registered{display:inline-block;padding:14px 26px;color:#ff6f61;font-size:32px;background-color:#fff;border-radius:4px;}
  }
  .card-box{
    margin:48px -10px 0;
    .item{float:left;width:25%;margin:0 4%;padding:0 10px;text-align:center;}
    .item-inner{
      padding:64px 0 30px;background:#fff;cursor:pointer;
      &:hover{box-shadow:0 2px 8px 0 rgba(255, 115, 101, 0.20);}
    }
    .ico{width:64px;height:64px;}
    .title{margin-top:34px;color:#292c2d;font-size:24px;}
  }
  .registration-process{
    margin-top:80px;padding:80px 0;background-image:linear-gradient(-45deg, #fe6f60 0%, #fad7a1 100%);
    .title{margin-bottom:48px;text-align:center;color:#fff;font-size:48px;}
  }
  .step-box{
    .item{position:relative;float:left;width:24%;text-align:center;}
    .num{width:72px;height:72px;margin:0 auto;line-height:72px;text-align:center;color:#ff6f61;font-size:48px;background-color:#fff;border-radius:50%;}
    .tit{margin-top:24px;color:#fff;font-size:24px;}
    .ps{margin-top:5px;opacity:0.8;;color:#fff;}
    .arr{position:absolute;top:20px;right:-24px;width:48px;height:32px;}
  }
  .adv-card{
    margin-top:20px;padding:32px;text-align:center;background:#fff;
    &:first-child{margin-top:48px;}
    &:hover{box-shadow:0 2px 8px 0 rgba(255, 115, 101, 0.20);}
    .title{color:#292c2d;font-size:24px;}
    .cont{margin-top:16px;color:#292c2d;font-size:16px;}
  }
</style>
